import React, { useState } from 'react';

import { ReactComponent as LogoIcon } from 'assets/vectors/logo.svg';
import { ReactComponent as GiftIcon } from 'assets/vectors/gift.svg';
import img from 'assets/images/login.png';

import AutoMargin from 'Components/AutoMargin';
import { useContext } from 'react';
import { UserContext } from 'Components/Context/UserContext';
import Loading from 'Components/Animations/Loading';
import { LanguageSwitcher } from '../../Components/LanguageSwitcher';
import { i18n } from 'utils/i18n/index';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export interface LoginWithAccessCodeProps {}

const LoginWithAccessCode: React.FC<LoginWithAccessCodeProps> = (props) => {
  const { loginWithAccessCode } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const { accessCode } = useParams<{ accessCode?: string }>();

  const handleLogin = () => {
    setIsLoading(true);
    if (accessCode == null || loginWithAccessCode == null) {
      setIsLoading(false);
      return toast.error('Algo deu errad');
    }
    loginWithAccessCode(accessCode).catch(() => setIsLoading(false));
  };

  return (
    <div className="flex justify-between items-stretch min-h-screen">
      <div className="hidden flex-col justify-around p-16 min-h-full bg-primary-darker w-7/12 rounded-r-xl lg:flex">
        <p className="font-normal text-7xl text-white">
          {i18n.t('boostText')}
          <br />
          {i18n.t('theValue')} <br />
          {i18n.t('ofConnections')}
        </p>
        <div className="flex justify-between">
          <div className="rounded-full w-1/4">
            <img src={img} alt="" className="rounded-full w-full h-auto" />
          </div>
          <div className=" relative transform translate-y-1/2 w-1/4">
            <div className="absolute transform -translate-y-1/2 rounded-full bg-secondary w-full h-full" />
            <div className="relative flex justify-center items-center w-full pb-full">
              <GiftIcon className="absolute stroke-current text-white w-1/2" />
              <div className="absolute transform -translate-x-1/2 border-r-0 border-solid border-white border w-screen h-full border-opacity-30" />
              <div className="absolute transform translate--1/2 -translate-y-1/2 border-b-0 border-solid border-white border w-full h-screen border-opacity-30" />
            </div>
          </div>
        </div>
      </div>
      <div
        className="absolute right-3 top-2 lg:absolute lg:right-6 lg:top-3" /*style={{position:"absolute", right:"50px", top: "10px"}}*/
      >
        <LanguageSwitcher />
      </div>
      <main className="flex justify-center items-center flex-grow">
        <AutoMargin className="w-full px-4 lg:w-96">
          <div className="flex gap-3 items-center font-bold text-4xl text-black mb-16 mt-10">
            <LogoIcon />
            meritu
          </div>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <button
                className="bg-white relative rounded-input shadow w-full h-11 flex justify-center items-center"
                onClick={handleLogin}
              >
                <p>Acessar a loja</p>
              </button>
            </>
          )}
        </AutoMargin>
      </main>
    </div>
  );
};

export default LoginWithAccessCode;
