import AutoMargin from 'Components/AutoMargin';
import { UserContext } from 'Components/Context/UserContext';
import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import history from 'utils/history';
import { RequetPurchaseSchema } from 'utils/validators/purchase';
import { i18n } from 'utils/i18n/index';
import { requestPurchase, sendMfaToEmail } from 'utils/api/purchase';
import { WalletModel } from 'utils/api/wallet';
import { ProductVariantModel } from 'utils/api/productsDisplay';
import InputText from 'Components/Formik/InputText';

interface ConfirmPurchaseProps {
  product: ProductVariantModel | undefined | null;
  close: () => void;
  values: {
    quantity: number;
    observation: string;
  };
}

type PartialPayment = {
  walletId: string | null;
  value: number;
  name: string;
  theme: string | null;
};

const ConfirmPurchase: React.FC<ConfirmPurchaseProps> = ({
  close,
  values,
  product,
}) => {
  const { user, updateUserContext } = useContext(UserContext);
  const [payment, setPayment] = useState<Array<PartialPayment>>([]);
  const [notEnoughMeritus, setNotEnoughMeritus] = useState(false);
  const [endDate, setEndDate] = useState(new Date());
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    setNotEnoughMeritus(false);
    if (!user?.meritus) return setNotEnoughMeritus(true);

    const totalPrice = (product?.meritus || 0) * values.quantity;

    //  get usable wallets
    const usableWallet = user?.wallets?.reduce(
      (prev, wallet) =>
        wallet.isDisabled !== true &&
        wallet.fund > 0 &&
        wallet.categories.some((categoryId) =>
          product?.categories?.includes(categoryId)
        )
          ? [...prev, wallet]
          : prev,
      [] as WalletModel[]
    );

    // calculate what is going to be deduced from each wallet
    const payment =
      usableWallet?.reduce((prev, wallet) => {
        const partialValue = prev.reduce((x, y) => x + y.value, 0);
        const remainingValue = totalPrice - partialValue;

        if (remainingValue <= 0) return prev;

        const value =
          wallet.fund >= remainingValue ? remainingValue : wallet.fund;

        return [
          ...prev,
          {
            name: wallet.name,
            walletId: wallet.id,
            value,
            theme: wallet.style.theme,
          },
        ];
      }, [] as Array<PartialPayment>) || [];

    // calc general
    const partialValue = payment.reduce((x, y) => x + y.value, 0);
    const remainingValue = totalPrice - partialValue;

    if (remainingValue > 0) {
      payment.push({
        name: 'Geral',
        walletId: null,
        value: remainingValue,
        theme: null,
      });
    }

    if (user.meritus - remainingValue <= 0) return setNotEnoughMeritus(true);
    // set payment state
    setPayment(payment || []);
  }, [product, user, values]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const timeLeft = calculateTimeLeft();
      setTimeLeft(timeLeft);
    }, 1000);

    return () => clearTimeout(timer);
  });

  if (!product || !user || !user.meritus)
    return (
      <AutoMargin className="flex-grow bg-white rounded w-full p-10">
        {i18n.t('somethingWrong')}
        <button className="rounded-full px-8 py-1 border" onClick={close}>
          {i18n.t('cancel')}
        </button>
      </AutoMargin>
    );

  const handleSubmit = (payload: typeof values, actions: any) => {
    actions.setSubmitting(true);

    const processedPayment = payment.map((x) => ({
      walletId: x.walletId,
      value: x.value,
    }));

    const promise = requestPurchase(product.id, {
      ...payload,
      payment: processedPayment,
    });

    toast.promise(promise, {
      pending: i18n.t('makingPurchase'),
      error: {
        render: () => {
          actions.setSubmitting(false);
          return i18n.t('somethingWrong');
        },
      },
      success: {
        render: () => {
          actions.setSubmitting(false);
          updateUserContext();
          history.push('/trocas');
          return i18n.t('purchaseMade');
        },
      },
    });
  };

  const calculateTimeLeft = () => {
    const difference = +endDate - +new Date();
    const seconds = Math.floor((difference / 1000) % 60);
    return seconds > 0 ? seconds : 0;
  };

  const handleSendEmail = () => {
    if(user.id == null) return toast.error('usuário não definido')
    // set end date to 30 seconds from now
    setEndDate(new Date(+new Date() + 30000));
    // send token to email
    toast.promise(sendMfaToEmail(user.id),{
      error: 'Algo deu errado no envio do e-mail',
      pending: 'enviando código para e-mail',
      success: 'e-mail enviado',
    })
  };

  const calcRemain = (gasto: {
    walletId: string | null;
    value: number;
    name: string;
  }) => {
    const wallet = user.wallets?.find((wallet) => wallet.id === gasto.walletId);
    const walletName = !wallet ? 'Geral' : wallet.name;
    const remains = !wallet
      ? (user.meritus || 0) - gasto.value
      : wallet.fund - gasto.value;
    return {
      walletName,
      remains,
    };
  };

  return (
    <AutoMargin className="flex-grow bg-white rounded w-full p-10">
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ ...values, code: '' }}
        validationSchema={RequetPurchaseSchema}
      >
        {({ isSubmitting, submitForm }) => (
          <>
            <p className="text-black text-xl">{i18n.t('exchangeMeritus')}</p>
            <p>{i18n.t('revewYourOrderAndFinalize')}</p>
            {/* <p className="text-sm font-bold text-red">{error}</p> */}
            <div className="justify-around w-full gap-6 mt-3">
              <label className="flex flex-col text-lg w-full">
                {`${i18n.t('unitaryValue')} ${product.meritus}`}
              </label>
              <label className="flex flex-col text-lg w-full">
                {`${i18n.t('quantity')}: ${values.quantity}`}
              </label>
              <label className="flex flex-col text-lg w-full">
                {`${i18n.t('Total')}: ${values.quantity * product.meritus}`}
              </label>
              {values.observation && (
                <label className="flex flex-col text-lg w-full">
                  {i18n.t('comments')}
                  <p className="text-base ml-3 whitespace-pre-wrap">
                    {values.observation}
                  </p>
                </label>
              )}
            </div>
            {/* <div className="rounded bg-neutral shadow p-5 mt-3 whitespace-nowrap grid grid-cols-3 gap-x-3 gap-y-2"> */}
            {!notEnoughMeritus ? (
              <>
                <div className="mt-7 grid grid-cols-4 gap-x-3 gap-y-2 font-semibold">
                  <p>{i18n.t('wallet')}</p>
                  <div className="flex justify-center">
                    <p>{i18n.t('have')}</p>
                  </div>
                  <div className="flex justify-center">
                    <p className="">{i18n.t('youWillSpend')}</p>
                  </div>
                  <div className="flex justify-center">
                    <p className="">{i18n.t('remain')}</p>
                  </div>
                </div>
                <hr className="col-span-3" />
                {payment.map((x) => (
                  <div
                    className={`grid grid-cols-4 gap-x-1 whitespace-nowrap mt-3 p-3 rounded-input border border-${
                      x.theme || 'primary-darker'
                    }`}
                    // className='grid grid-cols-4 gap-x-1 whitespace-nowrap mt-3'
                  >
                    <p className="mr-5">{x.name}:</p>
                    <div className="flex items-center justify-center">
                      {/* <Tag theme={x.theme}>{user.wallets?.find(y => y.walletId === x.walletId)?.fund || user.meritus}</Tag> */}
                      <p>
                        {user.wallets?.find((y) => y.walletId === x.walletId)
                          ?.fund || user.meritus}
                      </p>
                    </div>
                    <div className="flex items-center justify-center">
                      {/* <Tag theme={x.theme}>{x.value}</Tag> */}
                      <p>{x.value}</p>
                    </div>
                    <div className="w-full flex justify-center">
                      {/* <Tag theme={x.theme}>
                        {calcRemain(x).remains}
                      </Tag> */}
                      <p>{calcRemain(x).remains}</p>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="rounded bg-neutral shadow p-5 mt-3 ">
                {i18n.t('notEnoughCredits')}
              </div>
            )}
            <div className="mt-6">
              <hr className="" />
              <div className="p-3 rounded-input bg-gray mt-6">
                <p>Código de confirmação:</p>
                <div>
                  <button
                    className="rounded-full w-full bg-secondary py-1 mt-3 disabled:opacity-30"
                    onClick={handleSendEmail}
                    disabled={timeLeft > 0}
                  >
                    <p className="text-black">
                      {i18n.t('enviar código para e-mail')}
                      {timeLeft > 0 && ` (${timeLeft})`}
                    </p>
                  </button>
                  <p className="mt-3">Código:</p>
                  <InputText className="mt-3" name="token" />
                </div>
              </div>
            </div>

            <div className="flex mt-7 justify-between gap-5">
              <button className="rounded-full px-8 py-1 border" onClick={close}>
                {i18n.t('cancel')}
              </button>
              <button
                className="rounded-full bg-primary-darker px-8 py-1 text-white disabled:opacity-30"
                disabled={notEnoughMeritus || isSubmitting}
                onClick={submitForm}
              >
                {i18n.t('finish')}
              </button>
            </div>
          </>
        )}
      </Formik>
    </AutoMargin>
  );
};

export default ConfirmPurchase;
