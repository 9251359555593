import api from './axios';

export type AuthenticateResponse = {
  token: string;
  exp: number;
  domain: string;
  newTerms: boolean
};

export const userAuthenticate = async (params: {
  email: string;
  password: string;
}) => {
  const { data } = await api.request<AuthenticateResponse>({
    method: 'post',
    url: 'login',
    data: params,
  });
  return data;
};

export const userAuthWithIdToken = async (idToken: string) => {
  // console.log(idToken);
  
  const { data } = await api.request<AuthenticateResponse>({
    method: 'post',
    url: 'auth-idToken',
    data: { idToken },
  });
  return data;
};

export const authAccessCode = async (accessCode: string) => {
  const { data } = await api.request<AuthenticateResponse>({
    method: 'post',
    url: `auth-access-token/${accessCode}`,
  });
  return data;
};
