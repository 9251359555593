// import { getToken } from 'utils/jwt';
import api from './axios';
import { ProductModel } from './products';
import { UserModel } from './users';
import { WalletModel } from './wallet';

export interface PurchaseModel {
  createdAt: Date;
  meritus: number;
  productId: string;
  quantity: number;
  userId: string;
  status: string;
  id: string;
  additionalInformation: {
    observation: string;
  };
  product: ProductModel;
  payment?: Payment
}

export type Payment = Array<{
  walletId: WalletModel['id'] | null; // use null for general wallet
  value: number;
}>; 


export const listPurchase = async () => {
  const { data } = await api.request({
    method: 'get',
    url: 'disabledUser/purchase/byUser',
  });
  return data;
};

export const requestPurchase = async (
  productId: ProductModel['id'],
  payload: {
    quantity: number;
    observation: string;
    payment: Array<{ walletId: string | null; value: number }>;
  }
) => {
  const { data } = await api.request({
    method: 'post',
    url: `disabledUser/purchase/request/${productId}`,
    data: payload,
  });
  return data;
};

export const sendMfaToEmail = async (
  userId: UserModel['id'],
) => {
  const { data } = await api.request({
    method: 'post',
    url: `/disabledUser/mfa/sendToEmail/${userId}`,
  });
  return data;
};
