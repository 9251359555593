import React from 'react';

import { ReactComponent as LojaIcon } from '../../assets/vectors/loja.svg';
import { ReactComponent as LogoutIcon } from '../../assets/vectors/logout.svg';
import { ReactComponent as XIcon } from '../../assets/vectors/x.svg';

import Navbar from '../../Components/Navbar';
import history from '../../utils/history';
import { i18n } from 'utils/i18n/index';

interface MenuProps {}

const Menu: React.FC<MenuProps> = () => {
  return (
    <div className="min-h-screen w-screen overflow-hidden bg-primary-darker text-sm ">
      <Navbar alternative />
      <div className="bg-primary-darker w-full h-full flex justify-center lg:items-center lg:mt-16">
        <div className="w-4/5 lg:w-3/5 mt-3">
          <div className="flex justify-between items-center">
            <p className="text-xl lg:text-3xl text-white">
              {i18n.t('whereWantGo')}
            </p>
            <XIcon
              className="text-white stroke-current cursor-pointer w-5 lg:w-9"
              onClick={() => history.goBack()}
            />
          </div>
          <div className="grid lg:grid-cols-4 gap-5 mt-10">
            <div className="cursor-pointer relative w-full h-auto text-white bg-primary hover:bg-white hover:text-darker-90 lg:pb-full rounded-input">
              <div
                className="w-full h-full flex lg:flex-col py-3 lg:py-0 gap-3 ml-5 lg:ml-0 lg:justify-around items-center lg:absolute lg:top-1/2 lg:left-1/2 lg:transform lg:-translate-x-1/2 lg:-translate-y-1/2"
                onClick={() => history.push('/loja')}
              >
                <LojaIcon className="lg:mt-8 stroke-current  lg:w-1/3 w-1/6" />
                <p className=" text-xl lg:text-2xl font-normal">
                  {i18n.t('store')}
                </p>
              </div>
            </div>

            <div className="cursor-pointer relative w-full h-auto text-white bg-primary hover:bg-white hover:text-darker-90 lg:pb-full rounded-input">
              <div
                className="w-full h-full flex lg:flex-col py-3 lg:py-0 gap-3 ml-5 lg:ml-0 lg:justify-around items-center lg:absolute lg:top-1/2 lg:left-1/2 lg:transform lg:-translate-x-1/2 lg:-translate-y-1/2"
                onClick={() => history.push('/logout')}
              >
                <LogoutIcon className="lg:mt-8 stroke-current fill-current lg:w-1/3 w-1/6" />
                <p className=" text-xl lg:text-2xl font-normal">
                  {i18n.t('Logout')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
