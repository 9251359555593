export const messages = {
    es:{
        translations: {
            "boostText": "Impulsando",
            "theValue": "el valor",
            "ofConnections": "de las conexiones",
            "joinThePlatform": "Únete a la plataforma y descubre un mundo más colaborativo",
            "accessYourAccount": "Acceder a tu cuenta",
            "email": "Correo electrónico",
            "typeYourEmail": "Escriba su correo electrónico",
            "password": "Clave",
            "typeYourPassword": "Escribe tu contraseña",
            "forgotPassword": "Olvidé la contraseña",
            "login": "Entra",
            "or": "ou",
            "loginWith": "Entrar con",
            "invalidEmailPassword": "Correo electrónico o contraseña no válidos",
            "somethingWrong":"Algo salió mal",
            "recognizeColleague": "¡Reconoce a un colega!",
            "toRecognizeCollegues": "para reconocer a tus compañeros",
            "loading":"Cargando...",
            "noFeedBacks": "Parece que no hay reconocimientos en este momento",
            "youSawEverything": "¡Viste todo lo que tenías!",
            "pullDownToUpdate": "Desliza hacia abajo para actualizar",
            "releaseToUpdate": "Liberar para actualizar",
            "completedTheMission" : "completó la misión",
            "Logout":'Cerrar sesión',
            "myExchanges": "Mis intercambios",
            "listProductsServices": "Lista de productos y servicios",
            "noProducts": "Parece que no hay productos en este momento",
            "knowMore": "Para saber mas",
            "storeMenu": "TIENDA",
            "store": "Tienda",
            "whereWantGo": "¿A donde quieres ir?",
            "mission": "Misión",
            "profile": "Perfil",
            "profileOf": "Perfil de ",
            "attachFile": "Adjunte un archivo que demuestre que ha completado la búsqueda, puede ser una captura de pantalla, un video corto, un archivo de texto o cualquier otro archivo que muestre que has completado la misión a tus colegas :)",
            "mustAttachFile": "*Debe adjuntar un archivo",
            "missionMenu": "MISIÓN",
            "missions": "Misiones",
            "noMissions": "Parece que no hay misiones en este momento.",
            "expired": "Ha expirado:",
            "start": "Comienza:",
            "expires": "Caduca:",
            "recoverPassword": "recupera tu contraseña",
            "enterNewPassword":"Introduce tu nueva contraseña para acceder a la plataforma",
            "registerNewPassword":"Registrando nueva contraseña",
            "passwordRequestExpired": "Solicitud de recuperación de contraseña caducada",
            "typeNewPassword": "Introduce tu nueva contraseña para acceder a la plataforma",
            "passwordRegisterSuccessfully": "Contraseña registrada con éxito",
            "yourNewPassword": "Introduzca su nueva contraseña",
            "repeatYourNewPassword": "Repite tu nueva contraseña",
            "registerPassword": "Registrar contraseña",
            "cancel": "Cancelar",
            "error": "error",
            "confirmation": "Confirmación:",
            "quantity": "La cantidad",
            "typeQuantity": "Introduce la cantidad",
            "comments": "Comentarios",
            "typeComments": "Introduzca los comentarios",
            "exchangeMeritus": "Intercambio de meritus",
            "categories": "Categorías",
            "description": "Descripción",
            "seeOtherProducts": "Ver otros productos",
            "myExchangesTitle": "MIS INTERCAMBIOS",
            "noExchangeScore": "Parece que todavía no has cambiado los puntos.",
            "meritusSpent": "Meritus gastados",
            "observation": "Observación:",
            "date": "Fecha:",
            "status": "El estatus:",
            "updatingProfile": "Actualizando perfil",
            "updatedProfile": "Perfil actualizado.",
            "editProfile": "Editar perfil",
            "changeTheFieldsYouWantToUpdade": "Cambie los campos que desea actualizar, no es necesario que todos los campos estan llenos",
            "nickname/name": "Apellido / Nombre",
            "profilePicture": "Foto de perfil",
            "tellMeMoreAboutYou": "Hablé un poco sobre usted",
            "newPassword": "Nueva contraseña",
            "typeTheNewPassword": "Introduce la nueva contraseña",
            "repeatPassword": "Repite la contraseña",
            "typeAgain": "Escriba otra vez",
            "secondaryEmail": "Email secundario",
            "typeYourSecondaryEmail": "Ingrese su correo electrónico secundario",
            "cellphone": "Teléfono móvil",
            "typeCellphone": "Ingresar celular",
            "change": "Cambiar",
            "typeNickname": "Introduce un apodo",
            "completedMission": " completó la misión",
            "recognizedBy": " fue reconocido por ",
            "done": "¡Hecho!",
            "missionHasnotStarted": "¡La misión no ha comenzado!",
            "finish": "Finalizar",
            "participate": "Participar",
            "company": "Empresa",
            "profileMenu": "PERFIL",
            "myProfile": "Mi perfil",
            "editProfileButton": "Editar perfil",
            "changePassword": "Cambiar contraseña",
            "timeline": "Cronología de los agradecimientos recibidos",
            "changingPassword": "Cambio de contraseña.",
            "passwordChanged": "Contraseña cambiada.",
            "fillTheInput": "Complete los siguientes campos de entrada para cambiar su contraseña.",
            "companyMenu": "EMPRESA",
            "values": "Valores",
            "wallet": "Portafolio:",
            "toBeDistributed": "Para ser distribuido:",
            "enterYourEmailToRecoverPassword": "Ingrese su correo electrónico para iniciar el proceso de recuperación de contraseña",
            "sendingPasswordRecoveryEmail": "Envío de correo electrónico de recuperación de contraseña",
            "emailSent": "Email enviado",
            "TryAgainLater": "Error, inténtalo de nuevo más tarde",
            "recoverPasswordButton": "Recuperar contraseña",
            "recognizeYourCollegues": "¡Reconoce a tus compañeros!",
            "youHave": "Tú tienes",
            "creditsToDistribute": "creditos para repartir",
            "selectPerson": "Seleccione",
            "value": "Valor",
            "thisRecognitionIsRelatedTo": "Este reconocimiento está relacionado con:",
            "message": "Mensaje",
            "recognize": "Reconocer",
            "typeMessage": "Escribe un mensaje",
            "searchTenor": "Buscar Tenor",
            "search": "Buscar",
            "enjoy": "Me gusta",
            "hide": "Esconder",
            "comment": "Comentario",
            "writeComment": "Escribir un comentario...",
            "itdidnotWork": "Salió mal",
            "expiredMission": "Caducado!",
            "noImagesToShow": "No hay imágenes para mostrar",
            "readLess": "Leer menos",
            "readMore": "Lea mas",
            "doSearch": "Hacer una búsqueda",
            "credits": "creditos",
            "dateLocaleString": "es-ES",
            "recognizedYouFor": "te reconocí por ",
            "seeAllComments": "Ver todos los comentarios",
            "hideComments": "Ocultar comentarios",
            "pending": "Pendiente",
            "accepted": "Acepto",
            "makingPurchase": "Haciendo la compra",
            "purchaseMade": "Compra realizada",
            "revewYourOrderAndFinalize": "¡Revisa tu pedido y finaliza el intercambio!",
            "unitaryValue": "Valor unitario:",
            "youWillSpend": "Gastarás",
            "remain": "Quedará",
            "insufficient": "Saldo insuficiente",
            "exPired": "Caducado",
            "finishingTheMission": "Terminando la mision",
            "missionCompletedSuccessfully": "Misión completada con éxito",
            "acceptingMission": "Aceptando la misión",
            "missionAcceptedSuccessfully": "Misión aceptada con éxito",
            "noOptions": "No hay opciones",
            "showFilters": "Mostrar Filtros",
            "HideFilters": "Ocultar Filtros",
            "companyValues": "Valores de la empresa",
            "minimumPointValue": "Valor mínimo en puntos",
            "minimumPoints": "Valor mínimo",
            'maximumPointValue': "Valor máximo en puntos",
            "maximumValue": "Valor máximo",
            "missionStatus": "Status de la misión",
            "missionStartPeriod": "Período de inicio de la misión",
            "missionExpirationTime": "Tiempo de expiración de la misión",
            "clean": "Limpio",
            "inProgress": "En proceso",
            'notAccepted': "No aceptada",
            'whoWasRecognized': "Quem fue reconocido",
            "whoRecognized": "Quien reconoció",
            "whenRecognized": "Cuando reconocieron",
            "transactionType": "Tipo de transacción",
            "selection": "Elección",
            "productName": "Nombre del producto",
            "name": "Nombre",
            "datePicker": "Fecha",
            "notEnoughCredits": "No hay suficientes meritus",
            "have":"Tener",
            "completed": "Terminado",
            "howMuchToDistribute": "Cantidad de meritus que desea repartir a cada colega",
            "totalToDistribute": "Monto total a distribuir",
            "Click+ToRecognize": "¡Al hacer clic en el +, puede reconocer a varios colegas!",
            'termsOfUse': 'Términos de Uso',
            'toAccess': "Para acceder a nuestro servicio, debe leer y aceptar los Términos de uso",
            'accept': "Aceptar",
            'haveReadThe': 'He leído y acepto los',
            'termsOfService': 'términos de uso',
            'unableAcceptTerms': 'No se puede aceptar los términos de servicio',
            "EditProfile": "Editar perfil",
            "ChangePassword": "Cambiar contraseña",
            "ProcessingRecognition": "Procesamiento de reconocimiento",
            "RecognitionWasSuccessful": "Agradecimientos realizados con éxito",
            "General": "General",
            "Wallets": "Carteras",
            "Alimentação": "Alimentación",
            "Lazer": "Ocio",
            "Escritório": "Oficina",
            "Treinamento": "Capacitación",
            'generatingImageToShare': 'Generación de la imagen para compartir',
            'shareCompleted': 'Imagen generada',
            'from': 'desde',
        }
    }
}