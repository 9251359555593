import React, { useState, useEffect } from 'react';
import { Access, getUser, logoutApi, UserModel, whoAmI } from 'utils/api/users';
import { getCompany, CompanyModel } from 'utils/api/company';
import history from 'utils/history';
// import { getTokenPayload, logout } from 'utils/jwt';
import { useRouteMatch } from 'react-router-dom';
import { loginCookie, logoutCookie } from 'utils/jwt';
import { authAccessCode } from 'utils/api/authenticate';
import { toast } from 'react-toastify';

export type UserContext = {
  // undefined: not initialized, null: invalid session, string: valid session
  userId: string | undefined | null;
  user: Partial<UserModel> | undefined | null;
  company: CompanyModel | undefined | null;
  access: Access | undefined | null;
  updateUserContext: () => void;
  login: () => Promise<void>;
  logout: () => void;
  loginWithAccessCode: undefined | ((accessCode: string) => Promise<void>);
  acceptTerms: () => void;
};

export const UserContext = React.createContext<UserContext>({
  userId: undefined,
  user: undefined,
  company: undefined,
  access: undefined,
  updateUserContext: () => {},
  login: () => {
    return new Promise(() => {});
  },
  logout: () => {},
  loginWithAccessCode: undefined,
  acceptTerms: () => {},
});

export const UserContextProvider: React.FC = ({ children }) => { 
  const [user, setUser] = useState<UserContext['user']>();
  const [company, setCompany] = useState<UserContext['company']>();
  const [access, setAccess] = useState<UserContext['access']>();
  const [userId, setUserId] = useState<UserContext['userId']>();
  const [newTerms, setNewTerms] = useState<boolean>(false);

  const isLoginPage = useRouteMatch({ path: '/', exact: true });
  const isAccessPage = useRouteMatch({ path: '/acesso' });
  
  const logout = () => {
    logoutCookie();
    logoutApi().finally(() => {
      setUser(null);
      setUserId(null);
      history.push('/');
    });
  };

  const login = async () => {
    try {
      setUser(undefined);
      const { userId, access } = await whoAmI();
      const user = await getUser(userId);
      setAccess(access);
      setUser(user);
      setUserId(userId);
    } catch (error) {
      // console.log('Invalid session');
      // logout();
    }
  };

  const acceptTerms = () => {
    setNewTerms(false);
  };

  const loginWithAccessCode = async (accessCode: string) => {
    setUser(undefined);
    try {
      const { domain, exp, token, newTerms } = await authAccessCode(
        accessCode
      );
      setNewTerms(newTerms);
      loginCookie(token, exp, domain);
      login();
    } catch (error) {
      toast.error('Link invalido');
      history.push('/')
      setUser(null);
    }
  };

  const updateUserContext = () => {
    if (!userId) {
      setUser(null);
      history.replace('/');
    } else {
      getUser(userId).then(setUser);
    }
  };

  useEffect(() => {
    if (user?.companyId == null) {
      return;
    }
    getCompany(user.companyId).then(setCompany);
  }, [user?.companyId]);

  // decision making with user id
  useEffect(() => {
    if ((isLoginPage || isAccessPage) && userId && user) {
      if (newTerms) {
        return history.replace('/termsOfUse');
      }
      return history.replace('/loja');
    }

    if (userId === undefined) return;

    if (userId === null) {
      if (isLoginPage) return;
      if (isAccessPage) return;
      return history.replace('/');
    }

    if (user === undefined || user === null) {
      getUser(userId).then(setUser);
    }
  }, [
    user,
    isLoginPage,
    isAccessPage,
    userId,
    newTerms,
  ]);

  // setting userId
  useEffect(() => {
    if (userId !== undefined) return;

    whoAmI()
      .then(({ userId, access }) => {
        setUserId(userId);
        setAccess(access);
      })
      .catch(() => {
        logoutCookie();
        setUserId(null);
        setUser(null);
      });
  }, [userId]);

  return (
    <UserContext.Provider
      value={{
        user,
        userId,
        company,
        access,
        updateUserContext,
        login,
        logout,
        loginWithAccessCode,
        acceptTerms,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
