import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import history from './utils/history';

import {
  UserContextProvider,
} from 'Components/Context/UserContext';

import './global.css';

import Login from './Pages/Colaborador/Login';
import Loja from './Pages/Colaborador/Loja';
import Product from 'Pages/Colaborador/Product';
import Purchases from 'Pages/Colaborador/Purchases';
import Logout from 'Pages/Colaborador/Logout';
import TermsAndConditions from 'Pages/Colaborador/TermsOfService';
import LoginWithAccessCode from 'Pages/Colaborador/LoginWithAcessCode';

import { IdleTimer } from 'Components/IdleTimer';
import Menu from 'Pages/Colaborador/Menu';

function App() {
  return (
    <Router history={history}>
      <UserContextProvider>
        <IdleTimer />
        <Switch>
          <Route path="/menu/" exact component={Menu} />
          <Route path="/termsOfUse" exact component={TermsAndConditions} />
          <Route path="/" exact component={Login} />
          <Route path="/acesso/:accessCode" exact component={LoginWithAccessCode} />
          <Route path="/trocas" exact component={Purchases} />
          <Route path="/loja" exact component={Loja} />
          <Route
            path="/loja/:productDisplayId"
            exact
            component={Product}
          />
          <Route path="/logout" exact component={Logout} />
          <Route path="*">
            <div>404! url não encontrado</div>
          </Route>
        </Switch>
      </UserContextProvider>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Router>
  );
}

export default App;
