export const messages = {
    en:
        {translations: {
            "boostText": "Boosting",
            "theValue": "the value",
            "ofConnections": "of connections",
            "joinThePlatform": "Join the platform and discover a more collaborative world :)",
            "accessYourAccount": "Access your account",
            "email": "Email",
            "typeYourEmail": "Type your e-mail",
            "password": "Password",
            "typeYourPassword": "Type your password",
            "forgotPassword": "I forgot the password",
            "login": "Login",
            "or": "or",
            "loginWith": "Login with",
            "invalidEmailPassword": "Invalid email or password",
            "somethingWrong":"Something is wrong",
            "recognizeColleague":"Recognize a colleague!",
            "toRecognizeCollegues": "to recognize your colleagues",
            "loading": "Loading...",
            "noFeedBacks": "The are no feedback at moment",
            "youSawEverything": "Good! You saw everything in your feed",
            "pullDownToUpdate": "Pull down to update",
            "releaseToUpdate": "Release to update",
            "completedTheMission": "you completed de mission",
            "Logout": "Logout",
            "myExchanges": "My exchanges",
            "listProductsServices": "List of products and services",
            "noProducts": "There are no products at moment",
            "knowMore" : "Details",
            "storeMenu": "STORE",
            "store": "Store",
            "whereWantGo": "Where do you want to go?",
            "mission": "Mission",
            "profile": "Profile",
            "profileOf": "Profile of ",
            "attachFile": "Attach a file that demonstrates that you have completed the quest, it can be a screenshot, a short video, text file or any other files that show you have completed the quest to your colleagues :)",
            "mustAttachFile": "*Must attach a file",
            "missionMenu": "MISSION",
            "missions": "Missions",
            "noMissions": "There are no missions at moment",
            "expired": "Has expired:",
            "start": "Start:",
            "expires": "Expires:",
            "recoverPassword": "Recover your password",
            "enterNewPassword": "Enter your new password to access the platform",
            "registerNewPassword": "Registering new password",
            "passwordRequestExpired": "Password recovery request expired",
            "typeNewPassword": "Type your new password to access the platform",
            "passwordRegisterSuccessfully": "Password registered successfully",
            "yourNewPassword": "Type your new password",
            "repeatYourNewPassword": "Repeat your new password",
            "registerPassword": "Register password",
            "cancel": "Cancel",
            "error": "error",
            "confirmation": "Confirmation:",
            "quantity": "Quantity",
            "typeQuantity": "Type the quantity",
            "comments": "Comments",
            "typeComments": "Type the comments",
            "exchangeMeritus": "Exchange meritus",
            "categories": "Categories",
            "description": "Description",
            "seeOtherProducts": "Other products",
            "myExchangesTitle": "MY EXCHANGES",
            "noExchangeScore": "You haven't exchange your score yet",
            "meritusSpent": "Meritus spent",
            "observation": "Observation:",
            "date": "Date:",
            "status": "Status:",
            "updatingProfile": "Updating profile",
            "updatedProfile": "Updated profile.",
            "editProfile": "Edit profile",
            "changeTheFieldsYouWantToUpdade": "Change the fields you want to update, it is not necessary that all  the fields are filled",
            "nickname/name": "Nickname / Name",
            "profilePicture": "Profile picture",
            "tellMeMoreAboutYou": "Tell me more about you",
            "newPassword": "New password",
            "typeTheNewPassword": "Type the new password",
            "repeatPassword": "Repeat the password",
            "typeAgain":"Type again",
            "secondaryEmail": "Secondary email",
            "typeYourSecondaryEmail": "Type your secondary email",
            "cellphone": "Cellphone",
            "typeCellphone": "Type cellphone",
            "change": "Change",
            "typeNickname": "Type a nickname",
            "completedMission": " completed the mission",
            "recognizedBy": " was recognized by ",
            "done": "Done!",
            "missionHasnotStarted": "Mission has not started!",
            "finish": "Finish",
            "participate": "Participate",
            "company": "Company",
            "profileMenu": "PROFILE",
            "myProfile": "My Profile",
            "editProfileButton": "Edit profile",
            "changePassword": "Change password",
            "timeline": "Timeline of recognitions received",
            "changingPassword": "Changing password.",
            "passwordChanged": "Password changed",
            "fillTheInput": "Please fill in the following input fields to change your password.",
            "companyMenu": "COMPANY",
            "values": "Values",
            "wallet": "Wallet:",
            "toBeDistributed": "To be distributed",
            "enterYourEmailToRecoverPassword": "Enter your email to start the password recovery process",
            "sendingPasswordRecoveryEmail": "Sending password recovery email",
            "emailSent": "Email sent",
            "TryAgainLater": "Failed, try again later",
            "recoverPasswordButton": "Recover Password",
            "recognizeYourCollegues": "Recognize your colleagues!",
            "youHave": "You have",
            "creditsToDistribute": "credits to distribute",
            "selectPerson": "Select",
            "value": "Value",
            "thisRecognitionIsRelatedTo": "This recognition is related to:",
            "message": "Message",
            "recognize": "To recognize",
            "typeMessage": "Type a message",
            "searchTenor": "Search Tenor",
            "search": "Search",
            "enjoy": "Like",
            "hide": "Hide",
            "comment": "Comment",
            "writeComment": "Write a comment...",
            "itdidnotWork": "It did not work",
            "expiredMission": "Expired!",
            "noImagesToShow": "No images to show",
            "readLess": "Read less",
            "readMore": "Read more",
            "doSearch": "Do a search",
            "credits": "credits",
            "dateLocaleString": "en-US",
            "recognizedYouFor": "recognized you for ",
            "seeAllComments": "See all comments",
            "hideComments": "Hide comments",
            "pending":"Pending",
            "accepted": "Accepted",
            "makingPurchase": "Making purchase",
            "purchaseMade": "Purchase made",
            "revewYourOrderAndFinalize": "Review your order and complete the exchange!",
            "unitaryValue": "Unitary value:",
            "youWillSpend": "Spend",
            "remain": "Remain",
            "insufficient": "Not enough balance",
            "exPired": "Expired",
            "finishingTheMission": "Finishing the mission",
            "missionCompletedSuccessfully": "Mission completed successfully",
            "acceptingMission": "Accepting mission",
            "missionAcceptedSuccessfully": "Mission accepted successfully",
            "noOptions": "There are no options",
            "showFilters": "Show Filters",
            "HideFilters": "Hide Filters",
            "companyValues": "Company Values",
            "minimumPointValue": "Minimum point value",
            "minimumPoints": "Minimum value",
            'maximumPointValue': "Maximum point value",
            "maximumValue": "Maximum value",
            "missionStatus": "Mission status",
            "missionStartPeriod": "Mission start period",
            "missionExpirationTime": "Mission expiration time",
            "clean": "Clear",
            "inProgress": "In progress",
            'notAccepted': "Not accepted",
            'whoWasRecognized': "Who was recognized",
            "whoRecognized": "Who recognized",
            "whenRecognized": "When",
            "transactionType": "Transaction type",
            "selection": "Selection",
            "productName": "Product name",
            "name": "Name",
            "datePicker": "Date",
            "notEnoughCredits": "Not enough meritus",
            "have":"Have",
            "completed": "Completed",
            "howMuchToDistribute": "How many meritus to ditribute",
            "totalToDistribute": "Total to distribute",
            "Click+ToRecognize": "Click on + to recognize more co-workers!",
            'termsOfUse': 'Terms of use',
            'toAccess': "To access our service you have to read and accept the terms of use",
            'accept': "Accept",
            'haveReadThe': 'I have read and accept the',
            'termsOfService': 'terms of use',
            'unableAcceptTerms': 'We were unable to accept the terms of use',
            "EditProfile": "Edit Profile",
            "ChangePassword": "Change Password",
            "ProcessingRecognition": "Processing recognition",
            "RecognitionWasSuccessful": "Recognition was successful",
            "General": "General",
            "Wallets": "Wallets",
            "Alimentação": "Food",
            "Lazer": "Leisure",
            "Escritório": "Office",
            "Treinamento": "Training",
            'generatingImageToShare': 'Generating image to share',
            'shareCompleted': 'Image generated',
            'from': 'from',
        }
    }
}