// import jwtDecode from "jwt-decode";
import { domainCookie } from 'config';
export const TOKEN_KEY = '@jwt';

// export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
// export const isAuthenticated = () => tokenIsValid();

function getCookie(cname: string) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function setCookie(
  cname: string,
  cvalue: string,
  exdays: number,
  domain: string = ''
) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  const domainString = domain !== '' ? 'domain=' + domain + ';' : '';
  
  document.cookie =
    cname + '=' + cvalue + ';' + expires + ';path=/;' + domainString;
}

function delete_cookie(name: string) {
  setCookie(name, '', -2, '');
  setCookie(name, '', -2, domainCookie);
}

export const getToken = () => {
  // localStorage.getItem(TOKEN_KEY);
  return getCookie('jwtCookie');
};

// export const getTokenPayload = (): TokenPayload | null => {
//   return payload;
//   // const jwt = localStorage.getItem(TOKEN_KEY)

//   // try {
//   //   return jwtDecode(jwt || '')
//   // } catch (error) {
//   //   return  null
//   // }
// };

// /**
//  *
//  * @param params {email: string, password: string}
//  * @returns userId if authenticated or null if failed
//  */
// export const authenticate = async (params: {email: string, password: string}) => {
//   const token = await userAuthenticate(params)
//   login(token)
//   return getTokenPayload()?.sub;
// }

export const loginCookie = (token: string, exp: number, domain: string) => {
  // localStorage.setItem(TOKEN_KEY, token);
  setCookie('jwtCookie', token, exp, domainCookie);
};

export const logoutCookie = () => {
  delete_cookie('jwtCookie');
  // localStorage.removeItem(TOKEN_KEY);\
  // return logoutApi()
};
